"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
var Semaphore_1 = require("./Semaphore");
/**
 * A lock that can be used to synchronize critical sections in your code.
 * For more details on how to use this class, please view the documentation
 * of the Semaphore class from which Lock inherits.
 */
var Lock = (function (_super) {
    __extends(Lock, _super);
    /**
     * Creates a lock.
     */
    function Lock() {
        return _super.call(this, 1) || this;
    }
    return Lock;
}(Semaphore_1["default"]));
exports.Lock = Lock;
